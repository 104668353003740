import React, {useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import RequestSupport from "../../../components/pages/RequestSupport";

const IndexPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.hbspt.meetings.create(".meetings-iframe-container");
    }, 500);
  }, []);
  const {t} = useTranslation();
  return (
      <Layout>
        <Seo
            title={t('Calendar')}
            description={t('Calendar')}
        />
        <div className="contact consultation">
          <section>
            <div className="container">
              <h1 className={'headline'}><Trans>consult_headline</Trans></h1>
              <div>
                {/*Start of Meetings Embed Script*/}
                <div class="meetings-iframe-container"
                     data-src="https://meetings.hubspot.com/marie-montoya/digilock-consultation?embed=true"></div>
                <script type="text/javascript"
                        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
                {/*End of Meetings Embed Script */}

              </div>
              <div className={'consult-contact-info-copy'}>
                <h2 dangerouslySetInnerHTML={{__html: t('get_support')}}/>
                <p dangerouslySetInnerHTML={{__html: t('get_support_copy')}}/>
                <RequestSupport/>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
