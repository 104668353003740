import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import CustomLocalizedLink from "../locales/custom-localized-link";

const RequestSupport = () => {
  const {t} = useTranslation();
  return (
      <div className="sl-buttons">
        <CustomLocalizedLink
            label={t('request_support')}
            goto={'/contact/'}
            cls={'btn btn--orange page-brochure-button book-consultation'} />
      </div>
  )
}
export default RequestSupport;